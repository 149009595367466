import React, { useState, useEffect } from "react"
import { Button, Form } from "react-bootstrap"
import styled from "styled-components"
import { useForm } from "react-hook-form"

const ApplyNowForms = ({ handleClose,setStep,setMainData,setCommonLayoutData,commonLayoutData }) => {
  const { register, handleSubmit, watch, errors } = useForm()
  const [universities, setUniversities] = useState([])
  const [selectedUniversity, setSelectedUniversity] = useState(0)
  const [courses, setCourses] = useState([])
  const [selectedCourse, setSelectedCourse] = useState()
  const [error, setError] = useState("")
  const [colleges, setColleges] = useState([])
  const [campuses, setCampuses] = useState([])
  const enkiUrl = process.env.GATSBY_ENKI_URL;

  useEffect(() => {
    fetchUniversities()
    fetchUniversityCourses()
    fetchUniversityData()
  }, [])

  async function fetchUniversityData() {
    if (selectedUniversity === 30 || selectedUniversity === 46 || selectedUniversity === 111 || selectedUniversity === 57) {
      const response = await fetch(
        `${enkiUrl}/universities/get-info/${selectedUniversity}`
      )
      const responseJson = await response.json()
      if (selectedUniversity === 30 || selectedUniversity === 57)
        setColleges(responseJson.university.college)
      else if (selectedUniversity === 46 || selectedUniversity === 111)
        setCampuses(responseJson.university.campus)
    }
  }

  async function fetchUniversities() {
    const response = await fetch(
      `${enkiUrl}/universities/get-active-universities`
    )
    const responseJson = await response.json()
    let universities = responseJson.universities
    universities.sort((a, b) => a.name > b.name)
    universities.splice(0,0,{id:null,name: "Select University", short_name:"none"})
    setUniversities(universities)
  }

  const fetchUniversityCourses = async () => {
    const response = await fetch(
      `${enkiUrl}/course/get-university-courses`
    )
    const responseJson = await response.json()
    let data = responseJson.universityCourses;
    data.splice(0,0,{id:null,name:"Select Course"})
    setCourses(data)
  }


  const onSubmit = data => {
    if( data["course_duration"] === "0" || data["university_id"] === "Select University"){

      if(data["course_duration"] === "0"){
       setError("Please select course length")
      } 
      if(data["university_id"] === "Select University"){
        setError("Please select university")
      }
      
      if(data["university_course_id"] === "Select Course"){
        setError("Please select course")
      }
      
      return
    }
    console.log(data)
    setMainData(data);
    setCommonLayoutData(data);
    setStep(2);
    // console.log(data)
  }
  

  return (
    <StyledWrapper>
      <h1 className="mb-2 mb-md-2">YEAR OF COMPLETION</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
            <div className="col-sm-6 col-12">
                <Form.Label>What year did your A-Level qualifications?</Form.Label>
                <Form.Control
                type="text"
                //{...register("qualification", {required: true})} 
                name="qualification"
                placeholder="Enter your first name"
                defaultValue={commonLayoutData !== null ? commonLayoutData.qualification : ""}
                />
            </div>
            <div className="col-sm-6 col-12">
                <Form.Label>What year did you complete your GCSE studies?</Form.Label>
                <Form.Control
                type="text"
                //{...register("GCSE_year", {required: true})} 
                name="GCSE_year"
                placeholder="Enter your last name"
                defaultValue={commonLayoutData !== null ? commonLayoutData.GCSE_year : ""}
                />
            </div>
        </div>
        
        <h1 className="mt-4 mt-md-5">UNIVERSITY DETAILS</h1>
        <Form.Label>University</Form.Label>
        <Form.Control
        //{...register("university_id", {required: true})} 
        name="university_id"
        isInvalid={errors && errors.university}
        as="select"
        onChange={e => setSelectedUniversity(Number(e.target.value))}
        >
        {universities &&
            universities.length > 0 &&
              universities.map(university => (
                <option value={university.id} selected={university.id === Number(commonLayoutData?.university_id)}>{university.name}</option>
            ))}
        </Form.Control>
        <Form.Label>University Course</Form.Label>
        <Form.Control
        {...register("university_course_id", {required: true})} 
        name="university_course_id"
        isInvalid={errors && errors.university_course_id}
        as="select"
        onChange={e => setSelectedCourse(Number(e.target.value))}
        defaultValue={commonLayoutData !== null ? commonLayoutData.university_course_id : ""}
        >
        {courses &&
          courses.length > 0 &&
          courses.map(course => (
            <option value={course.id} selected={course.id === Number(commonLayoutData?.university_course_id)}>{course.name}</option>
          ))}
        </Form.Control>
        {selectedCourse === 96 && (
        <div>
            <Form.Label>Please enter your course</Form.Label>
            <Form.Control
            type="text"
            //{...register("university_course_other_text", {required: true})} 
            name="university_course_other_text"
            defaultValue={commonLayoutData !== null ? commonLayoutData.university_course_other_text : ""}
            />
        </div>
        )}

        <div className="row">
            <div className="col-sm-6 col-12">
                <Form.Label>Course Category</Form.Label>
                <Form.Control
                type="text"
                //{...register("course_category", {required: true})} 
                name="course_category"
                placeholder="Enter your first name"
                defaultValue={commonLayoutData !== null ? commonLayoutData.course_category : ""}
                />
            </div>
            <div className="col-sm-6 col-12">
                <Form.Label>Length of Course</Form.Label>
                <Form.Control as="select"   name="course_duration" defaultValue={commonLayoutData !== null ? commonLayoutData.course_duration : ""}>
                    <option value={0}>Select Length of Course</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                </Form.Control>
            </div>
        </div>

        <Form.Label>Current Year of Study (as of Sept 2020)</Form.Label>
        <Form.Control
        type="text"
        //{...register("current_year", {required: true})} 
        name="current_year"
        defaultValue={commonLayoutData !== null ? commonLayoutData.current_year : ""}
        />

        <Button className="flatButton mt-4 mt-md-5" type="submit">
          Next
        </Button>
        {error != "" ? <p className="fail-text">{error}</p> : <></>}
      </Form>
    </StyledWrapper>
  )
}

export default ApplyNowForms

const StyledWrapper = styled.div`
  @media (max-width: 600px) {
    width: 95%;
    padding-top: 8%;
    padding-bottom: 8%;
  }

  .form-control {
    height: 69px;
    width: 500px;
    background-color: #ececec;
    @media (max-width: 600px) {
      height: 50px;
      width: 100%;
    }
  }

  .form-label {
    font-family: "Nunito Sans";
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 20px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  .flatButton{
    height: 50px;
    width: 150px;
    background-color: #E43D5A;
    border:none;
  }

  .form-check {
    margin-top: 10px;
  }

  .form-check-input {
    transform: scale(1.2);
  }

  .cancel-btn {
    font-size: 16px;
    font-weight: bold;
    color: #464fd0;
    cursor: pointer;
    position: relative;
    top: 8px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .checkbox-link{
    display:inline-block;
    color: black;
    text-decoration: underline;
  }

  .fail-text{
    padding-top: 10px;
    padding-right: 80px;
    font-weight:bolder;
    color:#E43D5A;
  }

  .required-text{
    color:#E43D5A;
  }
`