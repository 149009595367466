import React from "react";
import styled from "styled-components";
import capitalizeFirstLetter from "../utils/capital";
import animation from "../images/students.mp4";
import { useEffect } from "react";

const StudentFeatures = ({ Work }) => {
  const { title } = Work;
  const word = title.split(" ")[0];
  const word2 = title.substr(16, 104);
  const text =
    capitalizeFirstLetter(word.toLowerCase()) +
    " " +
    title.split(" ")[1] +
    " " +
    word2.toLowerCase();
  useEffect(() => {
    var v = document.getElementById("Test_Video");
    v.playbackRate = 1;
  }, []);
  return (
    <StyleWrapper>
      <div className="container-fluid py-2 white-background">
        <div className="feature padding-left-8 padding-right-8">
          <h1 className="title">{Work.title}</h1>

          <p className="subtext">
            {Work.description !== null ? Work.description : ""}
          </p>
        </div>

        <div className="row video-sec padding-left-8 padding-right-8">
          <video id="Test_Video" autoPlay muted>
            <source src={animation} type="video/mp4" />
          </video>

          <div className="col-sm-12 button-section col-12 d-flex justify-content-center align-items-center">
            <a
              className="flatButton btn btn-default"
              target="_blank"
              href="https://realrating.co.uk/"
            >
              Get your REALrating
            </a>
          </div>
        </div>
      </div>
    </StyleWrapper>
  );
};

export default StudentFeatures;

const StyleWrapper = styled.div`
  .video-sec {
    margin-top: -1rem;
  }
  .button-section {
    margin-top: -2rem;
  }
  .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .subtext,
  .subheading {
    text-align: center;
  }
  .img-logo {
    margin: 0 auto;
  }

  .realWorkLogo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .realWorkLogo > img {
    height: 60px;
    object-fit: cover;
  }

  .subheading {
    padding: 0px 15px;
    margin-bottom: 0px;
  }

  .flatButton {
    background: #e43d5a;
    color: white;
    padding: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .subheading {
    font-size: 14px;
  }

  .title {
    text-align: center;
    line-height: 32px;
  }

  @media (min-width: 991px) {
    .subtext {
      width: 40%;
      text-align: center;
    }

    .title {
      padding-bottom: 20px;
      text-align: center;
      line-height: 43px;
    }

    .realWorkLogo {
      display: flex;
      justify-content: unset;
    }

    .realWorkLogo > img {
      height: 80px;
      object-fit: cover;
      padding-left: 20px;
    }

    .subheading {
      font-size: 16px;
      text-align: unset;
    }

    .flatButton {
      margin-top: 80px;
    }
  }
`;
