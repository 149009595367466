import React,{useState} from 'react';
import styled from 'styled-components'
import FirstForm from './FirstForm';
import SecondForm from './SecondForm';

const StyledWrapper = styled.div`
    background-color: white;
    padding: 5% 8%;
    overflow: scroll;
    @media (max-width: 600px) {
        width: 95%;
        padding-top: 8%;
        padding-bottom: 8%;
    }
    
    .form-label {
        font-family: "Nunito Sans";
        font-size: 21px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 20px;
        @media (max-width: 600px) {
          font-size: 15px;
          line-height: normal;
        }
        @media(min-width:991px){
            margin-top: 30px;
        }
    }
`

const FormMainComp = ({ handleClose,notify }) => {
    const [step,setStep] = useState(1);
    const [error,setError] = React.useState('');
    const [mainData,setMainData] = React.useState(null);
    const enkiUrl = process.env.GATSBY_ENKI_URL;
    const [disbaleBtn,setDisableBtn] = useState(false);
    const [commonLayoutData,setCommonLayoutData] = useState(null);
    const submitForm = data => {
        console.log(data)
        // setDisableBtn(true);
        // fetch(`${enkiUrl}/form/registration`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(data),
        // }).then(function(response){
        //   if(response.status == 200){
        //     notify();
        //     handleClose();
        //   } else {
        //     return response.json().then(data=>setError(data.message));
        //   }
        // }).catch(err => {console.log(err);setDisableBtn(false)});
        
      }
      
    return (
        <StyledWrapper>
            {step === 1 && <FirstForm setStep={setStep} handleClose={handleClose} setCommonLayoutData={setCommonLayoutData} commonLayoutData={commonLayoutData} setMainData={setMainData} />}
            {step === 2 && <SecondForm setStep={setStep} submitForm={submitForm} mainData={mainData} err={error}/> }
        </StyledWrapper>
    );
}

export default FormMainComp;