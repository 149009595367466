import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import FormModal from "./FormModal"
import ReactMarkdown from 'react-markdown'



const FormModalButton = () => {
  const [modalOpen, setModalOpen] = React.useState()

  return (
    
      <div className="apply-now margin-top" style={{background:'#E43D5A'}}>
        <span
          onClick={() => setModalOpen(!modalOpen)}
          className="flat-btn-link"
        >
        
        </span>
        {modalOpen && (
        <FormModal setModalOpen={setModalOpen} modalOpen={modalOpen}/>
      )}
      </div>
     
  )
}

export default FormModalButton
