import React,{useState} from 'react'
import { useForm } from 'react-hook-form';
import { Button,Form } from 'react-bootstrap';
import styled from 'styled-components'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


const SecondForm = ({setStep , submitForm,mainData, err}) => {
    const { register, handleSubmit, reset } = useForm({})
    const [disableBtn,setDisableBtn] = useState(false);
    const [error, setError] = useState("");
    const [str,setStr]=useState('')
    const [checkedVal1,setCheckedVal1]=useState(false)
    const [checkedVal2,setCheckedVal2]=useState(false)
    const [checkedVal3,setCheckedVal3]=useState(false)
    const [checkedVal4,setCheckedVal4]=useState(false)
    const [checkedVal5,setCheckedVal5]=useState(false)
    const [checkedVal6,setCheckedVal6]=useState(false)
    const [checkedVal7,setCheckedVal7]=useState(false)
    const [checkedVal8,setCheckedVal8]=useState(false)
    const [checkedVal9,setCheckedVal9]=useState(false)
    const [checkedVal10,setCheckedVal10]=useState(false)
    const [checkedVal11,setCheckedVal11]=useState(false)
    const [checkedVal12,setCheckedVal12]=useState(false)
    const [checkedVal13,setCheckedVal13]=useState(false)

    
    const [value, setValue] = React.useState('');

    const handleChange = (event) => {
      setValue(event.target.value);
    };
  
    console.log(mainData)
    const submitData = (data) => {
        setDisableBtn(true);
        let [cb1,cb2,cb3,cb4,cb5,cb6,cb7,cb8,cb9,cb10,cb11,cb12,cb13]={}
        if(checkedVal1 == true){
            cb1={checkboxValue1:str}
        }
        if(checkedVal2 == true){
            cb2={checkboxValue2:str}
        }
        if(checkedVal3 == true){
            cb3={checkboxValu3:str}
        }
        if(checkedVal4 == true){
            cb4={checkboxValue4:str}
        }
        if(checkedVal5 == true){
            cb5={checkboxValue5:str}
        }
        if(checkedVal6 == true){
            cb6={checkboxValue6:str}
        }
        if(checkedVal7 == true){
            cb7={checkboxValue7:str}
        }
        if(checkedVal8 == true){
            cb8={checkboxValue8:str}
        }
        if(checkedVal9 == true){
            cb9={checkboxValue9:str}
        }
        if(checkedVal10 == true){
            cb10={checkboxValue10:str}
        }
        if(checkedVal11 == true){
            cb11={checkboxValue5:str}
        }
        if(checkedVal12 == true){
            cb12={checkboxValue12:str}
        }
        if(checkedVal13 == true){
            cb13={checkboxValue13:str}
        }
        submitForm({...mainData,...data,gender:value,...cb1,...cb2,...cb3,...cb4,...cb5,...cb6,...cb7,...cb8,...cb9,...cb10,...cb11,...cb12,...cb13});


    }
    return (
        <StyleWrapper className="py-5 white-background">
            <h1 className="title pb-2">Get started here</h1>
            <Form onSubmit={handleSubmit(submitData)}>
                <Form.Label>What were the main qualifications that you completed to enter university? </Form.Label>
                <Form.Control
                type="text"
                name="main_qualifications"
                {...register("main_qualifications", {required: true})} 
                placeholder="John"
                /> 
                <Form.Label>What school did you complete your A-Levels (or equivalent) at?</Form.Label>
                <Form.Control
                type="text"
                name="school_Alevel"
                {...register("school_Alevel", {required: true})} 
                placeholder="John"
                /> 
                <Form.Label>What school did you complete your GCSEs (or equivalent) at?</Form.Label>
                <Form.Control
                type="text"
                name="school_GCSE"
                {...register("school_GCSE", {required: true})} 
                placeholder="John"
                /> 

                <Form.Label>What was your home postcode for where you mostly lived when you studied for your A-Levels (or equivalent)? </Form.Label>
                <Form.Control
                type="text"
                name="home_postcode"
                {...register("home_postcode", {required: true})} 
                placeholder="John"
                /> 

                <Form.Label>If different, what was your home postcode for where you mostly lived when you studied for your GCSEs (or equivalent)? </Form.Label>
                <Form.Control
                type="text"
                name="1"
                {...register("1", {required: true})} 
                placeholder="John"
                /> 
                
                <Form.Label>Gender</Form.Label>
                <div>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                    </RadioGroup>
                </FormControl>
                </div>
              
                <Form.Label>What was your home postcode for where you mostly lived when you studied for your A-Levels (or equivalent)? </Form.Label>
                <Form.Control
                type="text"
                name="2"
                {...register("2", {required: true})} 
                placeholder="John"
                /> 

                <Form.Label>If different, what was your home postcode for where you mostly lived when you studied for your GCSEs (or equivalent)? </Form.Label>
                <Form.Control
                type="text"
                name="3"
                {...register("3", {required: true})} 
                placeholder="John"
                /> 

                <div className="checkboxSet1">
                    <Form.Label>Pleast tick any statements that apply to you </Form.Label>
                    <label>
                        <input
                        name="checkbox_1"
                        value={checkedVal1}
                        type="checkbox"
                        onClick={_ => {setCheckedVal1(!checkedVal1);setStr('I have previously been eligible for Free School Meals in Year 11 or before')}}
            
                        />
                        I have previously been eligible for Free School Meals in Year 11 or before
                    </label>
                    <label>
                        <input 
                        name="checkbox_2"
                        value={checkedVal2}
                        type="checkbox"
                        onClick={_ => {setCheckedVal2(!checkedVal2);setStr('I have previously had refugee or asylum status ')}}
                        />
                        I have previously had refugee or asylum status 
                    </label>
                    <label>
                        <input 
                        name="checkbox_3"
                        value={checkedVal3}
                        type="checkbox"
                        onClick={_ => {setCheckedVal3(!checkedVal3);setStr('I have spent a period of 3 months or more in care')}}
                        />
                        I have spent a period of 3 months or more in care
                    </label>
                    <label>
                        <input
                        name="checkbox_4"
                        value={checkedVal4}
                        type="checkbox"
                        onClick={_ => {setCheckedVal4(!checkedVal4);setStr('I was responsible for caring for a family member whilst in school/college')}}
                        />
                        I was responsible for caring for a family member whilst in school/college
                    </label>
                    <label>
                        <input
                        name="checkbox_5"
                        value={checkedVal5}
                        type="checkbox"
                        onClick={_ => {setCheckedVal5(!checkedVal5);setStr('Neither of my parents have been to university')}}
                        />
                        Neither of my parents have been to university
                    </label>
                </div>

                <div className="checkboxSet2">
                    <Form.Label>How did you hear about the REALrating? </Form.Label>
                    <label>
                        <input
                        //ref={register({ required: 'This is required' })}
                        name="checkbox_6"
                        value={checkedVal6}
                        type="checkbox"
                        onClick={_ => {setCheckedVal6(!checkedVal6);setStr('University - email or twitter')}}
                        />
                        University - email or twitter
                    </label>
                    <label>
                        <input
                        //ref={register({ required: 'This is required' })}
                        name="checkbox_7"
                        value={checkedVal7}
                        type="checkbox"
                        onClick={_ => {setCheckedVal7(!checkedVal7);setStr('University - event. E.g Fresher’s Fair, Careers Fair ')}}
                        />
                        University - event. E.g Fresher’s Fair, Careers Fair 
                    </label>
                    <label>
                        <input
                        //ref={register({ required: 'This is required' })}
                        name="checkbox_8"
                        value={checkedVal8}
                        type="checkbox"
                        onClick={_ => {setCheckedVal8(!checkedVal8);setStr('University - society or other')}}
                        />
                        University - society or other
                    </label>
                    <label>
                        <input
                        //ref={register({ required: 'This is required' })}
                        name="checkbox_9"
                        value={checkedVal9}
                        type="checkbox"
                        onClick={_ => {setCheckedVal9(!checkedVal9);setStr('A friend')}}
                        />
                        A friend
                    </label>
                    <label>
                        <input
                        //ref={register({ required: 'This is required' })}
                        name="checkbox_10"
                        value={checkedVal10}
                        type="checkbox"
                        onClick={_ => {setCheckedVal10(!checkedVal10);setStr('Twitter')}}
                        />
                        Twitter
                    </label>
                    <label>
                        <input
                        //ref={register({ required: 'This is required' })}
                        name="checkbox_11"
                        value={checkedVal11}
                        type="checkbox"
                        onClick={_ => {setCheckedVal11(!checkedVal11);setStr('Others')}}
                        />
                        Others
                    </label>
                    <label>
                        <input
                        //ref={register({ required: 'This is required' })}
                        name="checkbox_12"
                        value={checkedVal12}
                        type="checkbox"
                        onClick={_ => {setCheckedVal12(!checkedVal12);setStr('Student Social Mobility Awards')}}
                        />
                        Student Social Mobility Awards
                    </label>
                    <label>
                        <input
                        //ref={register({ required: 'This is required' })}
                        name="checkbox_13"
                        value={checkedVal13}
                        type="checkbox"
                        onClick={_ => {setCheckedVal13(!checkedVal13);setStr('Aspire')}}
                        />
                        Aspire
                    </label>
                </div>
                
                
                   
                <div className="d-flex mt-5">
                    <div onClick={() => setStep(1)} className="cancel-btn">
                        Back
                    </div>
                    <Button className="flatButton" disabled={disableBtn} type="submit">
                        Submit
                    </Button>
                </div>  
                
                {error != "" ? <p className="fail-text">{error}</p> : <></>}
            </Form>
        </StyleWrapper>
    )
}

export default SecondForm

const StyleWrapper = styled.div`
@media (max-width: 600px) {
    width: 95%;
    padding-top: 8%;
    padding-bottom: 8%;
  }

  .form-control {
    height: 69px;
    width: 70%;
    background-color: #ececec;
    @media (max-width: 600px) {
      height: 50px;
      width: 100%;
    }
  }

  .form-label {
    font-family: "Nunito Sans";
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 30px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  .flatButton{
    height: 50px;
    width: 150px;
    background-color: #E43D5A;
    border:none;
  }

  .form-check {
    margin-top: 10px;
  }

  .form-check-input {
    transform: scale(1.2);
  }

  .cancel-btn {
    font-size: 16px;
    font-weight: bold;
    color: #464fd0;
    cursor: pointer;
    position: relative;
    top: 10px;
    margin-right:30px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .checkbox-link{
    display:inline-block;
    color: black;
    text-decoration: underline;
  }

  .fail-text{
    padding-top: 10px;
    padding-right: 80px;
    font-weight:bolder;
    color:#E43D5A;
  }

  .required-text{
    color:#E43D5A;
  }
  
  .MuiFormGroup-root{
        display:flex !important;
        flex-direction:row !important;
  }
  
  .checkboxSet1,.checkboxSet2{
      display:flex;
      flex-direction:column;
    }

  input{
      margin-right:8px;
  }

  label{
      margin-bottom:10px;
  }
`