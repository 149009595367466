import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import FormMainComp from "./FormMainComp"

function rand() {
  return Math.round(Math.random() * 20) - 10
}

function getModalStyle() {
  const top = 50 + rand()
  const left = 50 + rand()

  return {
    top: `${top}%`,
    margin: 'auto',
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export default function ApplyNowModal({ modalOpen, setModalOpen,notifyFn }) {
  const classes = useStyles()
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle)
  const [open, setOpen] = React.useState(true)

//   const handleOpen = () => {
//     setOpen(true)
//   }

  const handleClose = () => {
    setOpen(false);
    setModalOpen(false);
  }

  const body = (
    <FormMainComp handleClose={handleClose} />
  )

  return (
    <div>
      <Modal
        className="apply-now-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{display:'flex', justifyContent:'center'}}
      >
        {body}
      </Modal>
    </div>
  )
}
