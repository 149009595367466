import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import carouselArrowLeft from "../images/carousel-left-arrow.svg";
import carouselArrowRight from "../images/carousel-right-arrow.svg";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import SummaryBlock from "./SummaryBlock";
import ReactMarkdown from "react-markdown";

const StyledWrapper = styled.div`
  // Carousel Css

  .slider-slick {
    max-width: ${props => props.theme["percent-60"]};
    max-width: 100%;
    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  .slick-arrow {
    width: 72px;
    height: 72px;
  }

  .slick-next:before,
  .slick-prev:before {
    color: ${props => props.theme["borderBottomColor"]};
    font-size: ${props => props.theme["pixel-30"]};
    font-weight: bolder;
    line-height: 0.5;
  }

  .slick-prev {
    left: -6.7%;
    z-index: 1;
    background-color: #e43d5a !important;
  }

  .slick-next {
    right: -6.7%;
    z-index: 1;
    background-color: #e43d5a !important;
  }

  // .slick-next:hover, .slick-prev:hover {
  //   background-color: #E43D5A !important;
  // }

  // .testimonial-onhover-arrows:hover .slick-arrow {
  //     display: block !important;
  // }

  .slick-prev:before {
    content: url(${carouselArrowLeft});
    transform: rotate(180deg);
  }

  .slick-next:before {
    content: url(${carouselArrowRight});
  }

  .background-color {
    background-color: #304563;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .testimonial {
    color: #ffffff;
    font-size: 24px;
    font-weight: lighter;
    letter-spacing: 0;
    line-height: 42px;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: normal;
    }
  }

  .author {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  .testimonial-image {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  .flatButton {
    background: #e43d5a;
    color: white;
    padding: 18px;
    margin-top: 40px;
  }
  .random-background {
    color: black;
    border: 3px solid #e43d5a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .statistic-number {
    color: black;
    color: black;
    text-align: center;
    display: inherit;
  }
  .statistic-text {
    color: black;
    text-align: center;
  }

  .random-background {
    padding: ${props => props.theme["percent-10"]} 0
      ${props => props.theme["percent-10"]} 0;
    height: 300px;

    @media (min-width: 994px) and (max-width: 1120px) {
      min-width: 285px;
    }
    @media (min-width: 991px) {
      height: 350px;
    }
  }

  [class^="col"] {
    display: flex;
    flex-direction: column;
  }

  [class^="col"] div {
    flex-grow: 1;
  }
  .subheading {
    font-size: 16px;
    font-weight: 600;
    @media (min-width: 991px) {
      font-size: 24px;
    }
  }
  .subheading > p > a {
    text-decoration: underline;
    color: black;
  }

  .test {
    padding-top: 1rem;
  }
  @media screen and (max-width:1024px){
.testimonial-onhover-arrows{
  padding-right:3% !important;
  padding-left:3% !important;
}
.slick-slider{
  margin-right:0px;
  margin-left:0px;
}
  }
`;

const StatsCarousel = ({ stats, summary, text, showStats, showSummary }) => {
  const [focus, setFocus] = React.useState(false);
  var settings = {
    speed: 500,
    slidesToShow: 3,
    infinity: true,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          dots: true,
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <StyledWrapper>
      {showSummary && <SummaryBlock summary={summary} />}
      {showStats && (
        <div className="container-fluid testimonial-onhover-arrows pb-5 padding-left-8 padding-right-8">
          <Slider {...settings} className="slider-slick row">
            {React.Children.toArray(
              stats.map(item => (
                <div className="col-12 col-md-6 col-lg-4 mt-4 px-2">
                  <div
                    className="random-background d-flex justify-content-center padding-left-8 padding-right-8"
                    // style={{ backgroundColor: `${item.color}` }}
                  >
                    <div>
                      <CountUp
                        duration={2}
                        end={item.number !== null ? item.number : "-"}
                        start={focus ? 100 : null}
                        suffix={item.suffix}
                        prefix={item.prefix}
                        separator=","
                        decimals={item.decimals}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={isVisible => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                            delayedCall
                          >
                            <span
                              ref={countUpRef}
                              className="statistic-number"
                            />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <p className="statistic-text">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </Slider>
          <div className="col-sm-12 col-12 d-flex justify-content-center align-items-center">
            <a
              className="flatButton btn btn-default text-center"
              target="_blank"
              href="https://upreach.org.uk/"
            >
              Find out more
            </a>
          </div>
        </div>
      )}
    </StyledWrapper>
  );
};

export default StatsCarousel;
