import React from "react";
import StudentFeatures from "../components/StudentFeatures";
import Layout from "../components/layout";
import StudentForm from "../components/StudentForm";
import StatsCarousel from "../components/StatsCarousel";
import FormModalButton from "../components/Form/FormModalButton";
import SEO from "../components/seo";
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Students />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/real-studentpage`);
};
const Students = () => {
  const [newData, setNewData] = useState(null);
  const { data: pageData } = useQuery("students", fetchData);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);

  return (
    <Layout>
      {newData === null && <Loader />}
      {newData && <SEO title={newData.SEOtitle} />}

      {newData && newData.showStudentWorks && newData.StudentWorks && (
        <StudentFeatures Work={newData.StudentWorks} />
      )}
      {newData && newData.StudentStats && newData.StudentStats && (
        <StatsCarousel
          text={newData.StudentStats.linkText}
          stats={newData.StudentStats.stats}
          summary={newData.StudentStats.Summary}
          showStats={newData.showStats}
          showSummary={newData.showSummary}
        />
      )}

      <FormModalButton id="contact" />
      {newData && newData.showStudentForm && <StudentForm />}
    </Layout>
  );
};
